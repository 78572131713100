/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, HtmlHTMLAttributes, InputHTMLAttributes } from 'react';
export const a = (p: HtmlHTMLAttributes<HTMLAnchorElement> & {
  href?: string;
}) => <a {...p}>{p.children}</a>;
export const address = (p: HtmlHTMLAttributes<HTMLElement>) => <address {...p}>{p.children}</address>;
export const area = (p: HtmlHTMLAttributes<HTMLAreaElement>) => <area {...p}>{p.children}</area>;
export const article = (p: HtmlHTMLAttributes<HTMLElement>) => <article {...p}>{p.children}</article>;
export const aside = (p: HtmlHTMLAttributes<HTMLElement>) => <aside {...p}>{p.children}</aside>;
export const audio = (p: HtmlHTMLAttributes<HTMLAudioElement>) => <audio {...p}>{p.children}</audio>;
export const b = (p: HtmlHTMLAttributes<HTMLElement>) => <b {...p}>{p.children}</b>;
export const base = (p: HtmlHTMLAttributes<HTMLBaseElement>) => <base {...p}>{p.children}</base>;
export const blockquote = (p: HtmlHTMLAttributes<HTMLElement>) => <blockquote {...p}>{p.children}</blockquote>;
export const body = (p: HtmlHTMLAttributes<HTMLBodyElement>) => <body {...p}>{p.children}</body>;
export const br = (p: HtmlHTMLAttributes<HTMLBRElement>) => <br {...p}>{p.children}</br>;
export interface HtmlButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}
export const button: React.FC<HtmlButtonProps> = forwardRef<HTMLButtonElement, HtmlButtonProps>((p: HtmlButtonProps, ref) => <button type="button" ref={ref} {...p}>
    {p.children}
  </button>);
export const canvas = (p: HtmlHTMLAttributes<HTMLCanvasElement>) => <canvas {...p}>{p.children}</canvas>;
export const caption = (p: HtmlHTMLAttributes<HTMLElement>) => <caption {...p}>{p.children}</caption>;
export const cite = (p: HtmlHTMLAttributes<HTMLElement>) => <cite {...p}>{p.children}</cite>;
export const code = (p: HtmlHTMLAttributes<HTMLElement>) => <code {...p}>{p.children}</code>;
export const data = (p: HtmlHTMLAttributes<HTMLDataElement>) => <data {...p}>{p.children}</data>;
export const datalist = (p: HtmlHTMLAttributes<HTMLDataListElement>) => <datalist {...p}>{p.children}</datalist>;
export const dd = (p: HtmlHTMLAttributes<HTMLElement>) => <dd {...p}>{p.children}</dd>;
export const del = (p: HtmlHTMLAttributes<HTMLElement>) => <del {...p}>{p.children}</del>;
export const details = (p: HtmlHTMLAttributes<HTMLDetailsElement>) => <details {...p}>{p.children}</details>;
export const dfn = (p: HtmlHTMLAttributes<HTMLElement>) => <dfn {...p}>{p.children}</dfn>;
export const dialog = (p: HtmlHTMLAttributes<HTMLDialogElement>) => <dialog {...p}>{p.children}</dialog>;
export const div = (p: HtmlHTMLAttributes<HTMLDivElement>) => <div {...p}>{p.children}</div>;
export const dl = (p: HtmlHTMLAttributes<HTMLDListElement>) => <dl {...p}>{p.children}</dl>;
export const dt = (p: HtmlHTMLAttributes<HTMLElement>) => <dt {...p}>{p.children}</dt>;
export const em = (p: HtmlHTMLAttributes<HTMLElement>) => <em {...p}>{p.children}</em>;
export const embed = (p: HtmlHTMLAttributes<HTMLEmbedElement>) => <embed {...p}>{p.children}</embed>;
export const fieldset = (p: HtmlHTMLAttributes<HTMLFieldSetElement>) => <fieldset {...p}>{p.children}</fieldset>;
export const figcaption = (p: HtmlHTMLAttributes<HTMLElement>) => <figcaption {...p}>{p.children}</figcaption>;
export const figure = (p: HtmlHTMLAttributes<HTMLElement>) => <figure {...p}>{p.children}</figure>;
export const footer = (p: HtmlHTMLAttributes<HTMLElement>) => <footer {...p}>{p.children}</footer>;
export const form = (p: HtmlHTMLAttributes<HTMLFormElement>) => <form {...p}>{p.children}</form>;
export const head = (p: HtmlHTMLAttributes<HTMLHeadElement>) => <head {...p}>{p.children}</head>;
export const header = (p: HtmlHTMLAttributes<HTMLElement>) => <header {...p}>{p.children}</header>;
export const hgroup = (p: HtmlHTMLAttributes<HTMLElement>) => <hgroup {...p}>{p.children}</hgroup>;
export const h1 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h1 {...p}>{p.children}</h1>;
export const h2 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h2 {...p}>{p.children}</h2>;
export const h3 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h3 {...p}>{p.children}</h3>;
export const h4 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h4 {...p}>{p.children}</h4>;
export const h5 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h5 {...p}>{p.children}</h5>;
export const h6 = (p: HtmlHTMLAttributes<HTMLHeadingElement>) => <h6 {...p}>{p.children}</h6>;
export const hr = (p: HtmlHTMLAttributes<HTMLHRElement>) => <hr {...p}>{p.children}</hr>;
export const html = (p: HtmlHTMLAttributes<HTMLHtmlElement>) => <html {...p}>{p.children}</html>;
export const i = (p: HtmlHTMLAttributes<HTMLElement>) => <i {...p}>{p.children}</i>;
export const iframe = (p: HtmlHTMLAttributes<HTMLIFrameElement>) => <iframe {...p}>{p.children}</iframe>;
export const img = (p: HtmlHTMLAttributes<HTMLImageElement> & {
  src?: string;
  alt?: string;
}) => <img {...p}>{p.children}</img>;
export interface HtmlInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readOnly?: boolean;
}
export const input = (p: HtmlInputProps) => <input {...p}>{p.children}</input>;
export const ins = (p: HtmlHTMLAttributes<HTMLElement>) => <ins {...p}>{p.children}</ins>;
export const kbd = (p: HtmlHTMLAttributes<HTMLElement>) => <kbd {...p}>{p.children}</kbd>;
export const keygen = (p: HtmlHTMLAttributes<HTMLLegendElement>) => <keygen {...p}>{p.children}</keygen>;
export const label = (p: HtmlHTMLAttributes<HTMLLabelElement>) => <label {...p}>{p.children}</label>;
export const legend = (p: HtmlHTMLAttributes<HTMLLegendElement>) => <legend {...p}>{p.children}</legend>;
export const li = (p: HtmlHTMLAttributes<HTMLLIElement>) => <li {...p}>{p.children}</li>;
export const link = (p: HtmlHTMLAttributes<HTMLLinkElement>) => <link {...p}>{p.children}</link>;
export const main = (p: HtmlHTMLAttributes<HTMLElement>) => <main {...p}>{p.children}</main>;
export const mark = (p: HtmlHTMLAttributes<HTMLElement>) => <mark {...p}>{p.children}</mark>;
export const map = (p: HtmlHTMLAttributes<HTMLMapElement>) => <map {...p}>{p.children}</map>;
export const menu = (p: HtmlHTMLAttributes<HTMLMenuElement>) => <menu {...p}>{p.children}</menu>;
export const menuitem = (p: HtmlHTMLAttributes<HTMLMenuElement>) => <menuitem {...p}>{p.children}</menuitem>;
export const meta = (p: HtmlHTMLAttributes<HTMLMetaElement>) => <meta {...p}>{p.children}</meta>;
export const meter = (p: HtmlHTMLAttributes<HTMLMeterElement>) => <meter {...p}>{p.children}</meter>;
export const nav = (p: HtmlHTMLAttributes<HTMLElement>) => <nav {...p}>{p.children}</nav>;
export const object = (p: HtmlHTMLAttributes<HTMLObjectElement>) => <object {...p}>{p.children}</object>;
export const option = (p: HtmlHTMLAttributes<HTMLOptionElement> & {
  value: any;
}) => <option {...p}>{p.children}</option>;
export const output = (p: HtmlHTMLAttributes<HTMLOutputElement>) => <output {...p}>{p.children}</output>;
export const p = (p: HtmlHTMLAttributes<HTMLParagraphElement>) => <p {...p}>{p.children}</p>;
export const param = (p: HtmlHTMLAttributes<HTMLElement>) => <param {...p}>{p.children}</param>;
export const picture = (p: HtmlHTMLAttributes<HTMLPictureElement>) => <picture {...p}>{p.children}</picture>;
export const pre = (p: HtmlHTMLAttributes<HTMLPreElement>) => <pre {...p}>{p.children}</pre>;
export const progress = (p: HtmlHTMLAttributes<HTMLProgressElement>) => <progress {...p}>{p.children}</progress>;
export const q = (p: HtmlHTMLAttributes<HTMLQuoteElement>) => <q {...p}>{p.children}</q>;
export const rp = (p: HtmlHTMLAttributes<HTMLElement>) => <rp {...p}>{p.children}</rp>;
export const rt = (p: HtmlHTMLAttributes<HTMLElement>) => <rt {...p}>{p.children}</rt>;
export const s = (p: HtmlHTMLAttributes<HTMLElement>) => <s {...p}>{p.children}</s>;
export const script = (p: HtmlHTMLAttributes<HTMLScriptElement>) => <script {...p}>{p.children}</script>;
export const section = (p: HtmlHTMLAttributes<HTMLElement>) => <section {...p}>{p.children}</section>;
export const select = (p: HtmlHTMLAttributes<HTMLSelectElement>) => <select {...p}>{p.children}</select>;
export const small = (p: HtmlHTMLAttributes<HTMLElement>) => <small {...p}>{p.children}</small>;
export const source = (p: HtmlHTMLAttributes<HTMLSourceElement>) => <source {...p}>{p.children}</source>;
export const span = (p: HtmlHTMLAttributes<HTMLSpanElement>) => <span {...p}>{p.children}</span>;
export const strong = (p: HtmlHTMLAttributes<HTMLElement>) => <strong {...p}>{p.children}</strong>;
export const style = (p: HtmlHTMLAttributes<HTMLStyleElement>) => <style {...p}>{p.children}</style>;
export const sub = (p: HtmlHTMLAttributes<HTMLElement>) => <sub {...p}>{p.children}</sub>;
export const summary = (p: HtmlHTMLAttributes<HTMLElement>) => <summary {...p}>{p.children}</summary>;
export const svg = (p: HtmlHTMLAttributes<SVGSVGElement>) => <svg {...p}>{p.children}</svg>;
export const table = (p: HtmlHTMLAttributes<HTMLTableElement>) => <table {...p}>{p.children}</table>;
export const tbody = (p: HtmlHTMLAttributes<HTMLTableSectionElement>) => <tbody {...p}>{p.children}</tbody>;
export const td = (p: HtmlHTMLAttributes<HTMLTableDataCellElement>) => <td {...p}>{p.children}</td>;
export const template = (p: HtmlHTMLAttributes<HTMLTemplateElement>) => <template {...p}>{p.children}</template>;
export const textarea = (p: HtmlHTMLAttributes<HTMLTextAreaElement>) => <textarea {...p}>{p.children}</textarea>;
export const tfoot = (p: HtmlHTMLAttributes<HTMLTableSectionElement>) => <tfoot {...p}>{p.children}</tfoot>;
export const th = (p: HtmlHTMLAttributes<HTMLTableCellElement>) => <th {...p}>{p.children}</th>;
export const thead = (p: HtmlHTMLAttributes<HTMLTableSectionElement>) => <thead {...p}>{p.children}</thead>;
export const time = (p: HtmlHTMLAttributes<HTMLTimeElement>) => <time {...p}>{p.children}</time>;
export const title = (p: HtmlHTMLAttributes<HTMLTitleElement>) => <title {...p}>{p.children}</title>;
export const tr = (p: HtmlHTMLAttributes<HTMLTableRowElement>) => <tr {...p}>{p.children}</tr>;
export const track = (p: HtmlHTMLAttributes<HTMLTrackElement>) => <track {...p}>{p.children}</track>;
export const u = (p: HtmlHTMLAttributes<HTMLElement>) => <u {...p}>{p.children}</u>;
export const ul = (p: HtmlHTMLAttributes<HTMLUListElement>) => <ul {...p}>{p.children}</ul>;
export const video = (p: HtmlHTMLAttributes<HTMLVideoElement>) => <video {...p}>{p.children}</video>;
export const wbr = (p: HtmlHTMLAttributes<HTMLElement>) => <wbr {...p}>{p.children}</wbr>;
export const sup = (p: HtmlHTMLAttributes<HTMLElement>) => <sup {...p}>{p.children}</sup>;