import { css } from 'styled-components';

// Types
import type { ButtonProps } from '.';

// Size specific styled css
export const buttonLCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    padding: 10px 14px;
    font-size: ${theme.fontSize.m};
    border-radius: ${theme.borderRadius.m};

    .base-button-adornment {
      max-height: 20px;
    }
  `};
`;
export const buttonMCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    padding: 10px 14px;
    height: ${theme.height.m};
    font-size: ${theme.fontSize.xs};
    border-radius: ${theme.borderRadius.s};

    .base-button-adornment {
      max-height: 18px;
    }
  `};
`;