import { forwardRef } from 'react';

// Styles
import { StyledButton, StyledButtonProps } from './styles';

// Types
export interface ButtonProps extends StyledButtonProps {}

// Component
export const Button = forwardRef(({
  size = 'l',
  variant = 'primary',
  media = true,
  ...props
}: ButtonProps, ref) => <StyledButton $media={media} {...{
  size,
  variant,
  ref
}} {...props}>
      {props.children}
    </StyledButton>);
Button.displayName = 'Button';