import { css } from 'styled-components';

// Types
import type { ButtonProps } from '.';
export const buttonSecondaryCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    background-color: ${theme.color.primary + '1A'};
    color: ${theme.color.primary};

    &[data-disabled='true'] {
      color: ${theme.color.grey};
      background-color: ${theme.color.grey50};
    }

    &[data-loading='true'] {
      background-color: ${theme.color.primary};
    }

    &[data-active='true'] {
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
    }
  `};
`;