import { css } from 'styled-components';

// Types
import type { ButtonProps } from '.';
export const buttonPlainCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    background-color: transparent;
    color: ${theme.color.dark400};

    &[data-disabled='false'],
    &[data-loading='false'] {
      &:hover {
        background-color: ${theme.color.grey40};
      }
    }

    &[data-active='true'] {
      background-color: ${theme.color.grey50};
    }
  `};
`;