// Types
import type { RuleSet } from 'styled-components';

/**
 * Converts a media object with breakpoint names as keys and styled CSS as values into selected CSS rules based on the mediaProp.
 *
 * @param mediaObj - The media object with breakpoint names as keys and styled CSS as values.
 * @param mediaProp - Specifies the breakpoints to retrieve from mediaObj and return as styles for the component. Pass false to disable media queries.
 * @returns The selected CSS rules collected from mediaObj based on the mediaProp, or an empty string depending on the mediaProp value.
 */
export const mediaObjToStyled = (
  mediaObj: Record<string, RuleSet<any>>,
  mediaProp?: boolean | string[] | string
): RuleSet<any> | string | string[] => {
  if (mediaProp === false) return '';
  if (mediaProp === true || typeof mediaProp === 'undefined') {
    // If mediaProp is true or undefined, return all CSS rules from mediaObj
    return Object.values(mediaObj);
  }

  const mediaPropArr = Array.isArray(mediaProp)
    ? (mediaProp as string[])
    : [mediaProp as string];

  const mediaCssArr = mediaPropArr.map((media: string) => {
    const mediaRuleSet = mediaObj[media];
    if (!mediaRuleSet) return '';

    return mediaRuleSet;
  });

  return mediaCssArr;
};
