import { css, styled, RuleSet } from 'styled-components';

// Components
import { Button as ParentButton, ButtonProps as ParentButtonProps } from '@ui/base-button';

// Types
import type { ThemeHeight, ThemeMediaQuery } from 'shared/theme/types';

// Utils
import { mediaObjToStyled } from '@tools/ts-media-obj-to-styled';

// Styles
import { buttonTabletCss } from './media-styles';
import { buttonPrimaryCss } from './primary-styles';
import { buttonOutlinedCss } from './outlined-styles';
import { buttonSecondaryCss } from './secondary-styles';
import { buttonPlainCss } from './plain-styles';
import { buttonMCss, buttonLCss } from './size-styles';

// Types
export type ButtonSizes = keyof ThemeHeight | 'none';
export type ButtonBreakpoints = keyof ThemeMediaQuery;
export type ButtonVariants = 'primary' | 'outlined' | 'secondary' | 'plain' | 'none';
export interface StyledButtonProps extends ParentButtonProps {
  size?: ButtonSizes;
  variant?: ButtonVariants;
  media?: boolean | Array<ButtonBreakpoints> | ButtonBreakpoints;
  $media?: boolean | Array<ButtonBreakpoints> | ButtonBreakpoints;
}

// Css objects
const sizesCssObj: Partial<Record<ButtonSizes, RuleSet<StyledButtonProps>>> = {
  m: buttonMCss,
  l: buttonLCss
};
const mediaCssObj: Partial<Record<ButtonBreakpoints, RuleSet<StyledButtonProps>>> = {
  tablet: buttonTabletCss
};
const variantsCssObj: Partial<Record<ButtonVariants, RuleSet<StyledButtonProps>>> = {
  primary: buttonPrimaryCss,
  outlined: buttonOutlinedCss,
  secondary: buttonSecondaryCss,
  plain: buttonPlainCss
};

// Button styled css
export const buttonCss = css<StyledButtonProps>`
  ${({
  theme,
  size,
  variant,
  media,
  $media = media
}) => css`
    height: ${size ? theme?.['height']?.[size] : 'auto'};
    font-weight: ${theme?.['fontWeight']?.['medium']};
    gap: 5px;
    transition: background-color 0.2s ease-in-out;
    text-align: center;

    ${sizesCssObj[size as ButtonSizes]}
    ${mediaObjToStyled(mediaCssObj, $media)}
    ${variantsCssObj[variant as ButtonVariants]}
  `};
`;
// Styled components
export const StyledButton: any = styled(ParentButton)<StyledButtonProps>`
  ${buttonCss};
`;