import { css } from 'styled-components';

// Styles
import { buttonMCss } from './size-styles';

// Types
import { ButtonProps } from '.';

// Button media queries styled css
export const buttonTabletCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    ${theme.media.tablet} {
      ${buttonMCss};
    }
  `};
`;