import { css } from 'styled-components';

// Types
import type { ButtonProps } from '.';
export const buttonOutlinedCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    border: 1px solid ${theme.color.grey100};
    background-color: transparent;
    color: ${theme.color.dark400};

    &[data-disabled='false'],
    &[data-loading='false'] {
      &:hover {
        background-color: ${theme.color.grey50};
      }
    }

    &[data-active='true'] {
      background-color: ${theme.color.grey50};
    }
  `};
`;