/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef, HtmlHTMLAttributes } from 'react';

// Styles
import s from './styles.module.css';

// Base HTML Tags
import { button, span } from '@ui/base-html-tags';

// Icons
import TailSpinIcon from '../assets/tail-spin.svg';

// Re export components
export { TailSpinIcon };

// Interfaces
export interface ButtonSpecificProps {
  ButtonComponent?: React.FC<ButtonProps> | React.FC<any>;
  LoadingComponent?: React.FC<HtmlHTMLAttributes<SVGElement>> | React.FC<any>;
  StartAdornment?: React.FC<HtmlHTMLAttributes<SVGElement>> | React.FC<any>;
  EndAdornment?: React.FC<HtmlHTMLAttributes<SVGElement>> | React.FC<any>;
  CenterAdornment?: React.FC<HtmlHTMLAttributes<SVGElement>> | React.FC<any>;
  CenterWrap?: React.FC<HtmlHTMLAttributes<HTMLSpanElement>> | React.FC<any>;
  loadingClass?: string;
  adornmentClass?: string;
  startAdornmentClass?: string;
  endAdornmentClass?: string;
  centerAdornmentClass?: string;
  centerWrapClass?: string;
  loading?: boolean;
  size?: string;
  variant?: string;
  clsPrefix?: string;
  disabled?: boolean;
  active?: boolean;
}
export interface ButtonProps extends ButtonSpecificProps, HtmlHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  ref?: React.Ref<HTMLButtonElement>;
}
const StubComponent = (p: any) => <div {...p} />;

/**
 * This is an implementation example for base button.
 * Use it's props to create your own button based on some UI library.
 * As you can see any part of the button can be replaced with your own component. Button has different data attributes for loading, size and active state because it's more convenient for access by CSS selectors.
 *
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  ButtonComponent = button,
  StartAdornment = StubComponent,
  EndAdornment = StubComponent,
  CenterAdornment,
  LoadingComponent = TailSpinIcon,
  CenterWrap = span,
  adornmentClass = '',
  startAdornmentClass = '',
  endAdornmentClass = '',
  centerAdornmentClass = '',
  centerWrapClass = '',
  loadingClass = '',
  size = '',
  variant = '',
  disabled = false,
  loading = false,
  active = false,
  clsPrefix = 'base-button',
  className = '',
  ...props
}: ButtonProps, ref) => {
  return <ButtonComponent disabled={disabled} data-active={active} data-loading={loading} data-size={size} data-variant={variant} data-disabled={disabled} className={`${s.button} ${clsPrefix} ${className}`}
  // @ts-ignore
  ref={ref} {...props}>
        {loading ? <LoadingComponent className={`${s.loading} ${clsPrefix}-loading ${loadingClass}`} /> : <>
            <StartAdornment className={`${clsPrefix}-adornment ${clsPrefix}-start-adornment ${adornmentClass} $ ${startAdornmentClass}`} />

            <CenterWrap className={`${s.centerWrap} ${clsPrefix}-center-wrap ${centerWrapClass}`}>
              {CenterAdornment ? <CenterAdornment className={`${clsPrefix}-adornment ${clsPrefix}-center-adornment ${adornmentClass} ${centerAdornmentClass}`} /> : props.children}
            </CenterWrap>

            <EndAdornment className={`${clsPrefix}-adornment ${clsPrefix}-end-adornment ${adornmentClass} ${endAdornmentClass}`} />
          </>}
      </ButtonComponent>;
});